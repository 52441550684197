

@font-face {
  font-family: heming;
  src: url(media/heming.ttf);
}

body {
  margin: 0;
  font-family: "heming", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

.App {
  display: flex;
  justify-content: center;
}
.content {
  padding: 10px;
  max-width: 800px;
  width: 100%;
  text-align: left;
}
h1 {
  /* text-align: center; */
  margin-bottom: 80px;
  font-size: 50px;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-bottom: 60px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

a {
  color: white;
}

.fa9e1a {
  color: black !important;
}
.bef821 {
  color: black !important;
  font-family: "heming" !important;
}

.bee42b {
  color: black;
  font-family: "heming" !important;
}

.player {
  margin-bottom: 80px;
}

.c7b08a {
  font-family: "heming" !important;
}

.df7adc {
  font-family: "heming" !important;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

span{
  font-weight: bold;
}